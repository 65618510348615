<script>
import ApiService from "@services/api.service";
import ImgCarousel from "@/components/ImgCarousel.vue";
import draggable from "vuedraggable";

export default {
  name: "FlyerDetail",
  components: { ImgCarousel, draggable },
  props: {
    flyerCode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      flyer: {
        code: null,
        description: null,
        coverImageUrl: null,
        filePayload: {
          filename: null,
          base64File: null
        },
        flyerSlides: [],
        imgPayload: null
      }
    };
  },
  computed: {
    editMode() {
      return this.flyerCode !== null;
    }
  },
  created() {
    if (this.flyerCode) this.loadFlyer();
  },
  methods: {
    loadFlyer() {
      this.changeLoader(true);
      let qs = {
        code: this.flyerCode
      };
      ApiService.get("flyer/detail", qs)
        .then(resp => {
          this.flyer = resp;
          this.flyer.filePayload = {
            filename: null,
            base64File: null
          };
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    addCarousel(file) {
      this.changeLoader(true);
      let payload = {
        modelObject: {
          flyerCode: this.flyerCode
        },
        filePayload: file
      };
      ApiService.post("flyer/slide", payload)
        .then(() => {
          this.showToast("success", "Upload Image Successful");
          this.loadFlyer();
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    async removeCarousel(record) {
      if (await this.showConfirm(`Delete File ?`)) {
        this.changeLoader(true);
        let qs = {
          code: record.code
        };
        ApiService.delete("flyer/slide", qs)
          .then(() => {
            this.showToast("success", "Delete Successful");
            this.loadFlyer();
          })

          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    save() {
      if (!this.editMode) {
        this.changeLoader(true);
        let payload = {
          modelObject: {
            description: this.flyer.description
          }
        };
        if (this.flyer.filePayload.filename != null) payload.filePayload = this.flyer.filePayload;
        ApiService.post("flyer", payload)
          .then(resp => {
            this.showToast("success", "Create Successful");
            this.$router.push({ name: "cms-flyer-detail", params: { flyerCode: resp.code } });
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        let payload = {
          modelObject: {
            code: this.flyer.code,
            description: this.flyer.description
          }
        };
        if (this.flyer.filePayload.base64File != null) payload.filePayload = this.flyer.filePayload;
        ApiService.put("flyer", payload)
          .then(() => {
            this.showToast("success", "Edit Successful");
            this.$router.push({ name: "cms-flyer-item" });
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    saveSequence() {
      this.changeLoader(true);
      var x = this.flyer.flyerSlides.length;
      var payload = [];
      var i;
      for (i = 0; i < x; i++) {
        var a = {
          code: this.flyer.flyerSlides[i].code,
          sequence: i
        };
        payload.push(a);
      }
      ApiService.patch("flyer/slide", payload)
        .then(() => {
          this.showToast("success", "Save Sequence Successful");
          this.loadFlyer();
        })

        .finally(() => {
          this.changeLoader(false);
        });
    },
    fileChanged(event) {
      var reader = new FileReader();
      this.flyer.filePayload.filename = event.target.files[0].name;
      reader.onloadend = () => {
        this.flyer.filePayload.base64File = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    fileChangedSlide(event) {
      let file = {
        filename: event.target.files[0].name
      };
      var reader = new FileReader();
      reader.onloadend = () => {
        file.base64File = reader.result;
        this.addCarousel(file);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-row>
        <b-col class="text-center">
          <h2>{{ !editMode ? `Create Flyer` : `Edit Flyer` }}</h2>
        </b-col>
      </b-row>
      <hr />

      <b-form @submit.stop.prevent="save">
        <b-row class="mb-2 mt-2">
          <b-col md="4">
            <label>Description</label>
          </b-col>
          <b-col md="8">
            <b-input v-model="flyer.description" class="form-control" />
            <small>Flyer Description</small>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" align-self="center">
            <label>Cover Image</label>
          </b-col>
          <b-col md="8" align-self="center">
            <b-form-file
              class="ml-auto"
              placeholder="Upload more Image..."
              drop-placeholder="Drop image here..."
              accept="image/jpeg, image/png, image/jpg"
              @change="fileChanged"
            ></b-form-file>
          </b-col>
        </b-row>
        <b-row class="d-flex ml-auto mt-5">
          <b-col>
            <router-link :to="{ name: 'cms-flyer-item' }">
              <b-btn block variant="danger">Cancel</b-btn>
            </router-link>
          </b-col>
          <b-col>
            <b-btn block type="submit" variant="success">Save</b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-row v-if="editMode">
      <b-col>
        <b-card>
          <b-row>
            <b-col class="text-center">
              <h2>Image Collection</h2>
            </b-col>
          </b-row>
          <hr />
          <b-card-title class="d-flex">
            <b-form-file
              class="ml-auto mr-2"
              placeholder="Upload more Image..."
              drop-placeholder="Drop image here..."
              accept="image/jpeg, image/png, image/jpg"
              @change="fileChangedSlide"
            ></b-form-file>
            <b-button variant="outline-primary" size="sm" @click.stop="saveSequence()"> Save Sequence </b-button>
          </b-card-title>
          <draggable v-model="flyer.flyerSlides" class="row cursor-move" tag="ul">
            <b-col v-for="item in flyer.flyerSlides" :key="item.code" class="mr-1 bg-light h-100 border border-dark p-1" align-self="center" md="2">
              <div class="text-center align-middle h-100"><b-img :src="item.imageUrl" fluid width="200" /></div>
              <div class="mt-2 text-center align-middle">
                <b-button variant="danger" @click.stop="removeCarousel(item)">
                  <span>Remove</span>
                  <feather-icon icon="TrashIcon" class="text-light ml-1" />
                </b-button>
              </div>
            </b-col>
          </draggable>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<style>
.list-group-item {
  transition: all 1s;
}
</style>
